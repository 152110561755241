.App {
  position: relative;
  overflow: clip;
  background: linear-gradient(47deg, rgb(0, 0, 0) 36%, rgb(8, 7, 18) 100%);
}

.white-gradient {
  position: absolute;
  width: 10rem;
  height: 10rem;
  background: #143af7;
  filter: blur(150px);
  border-radius: 100px;
  right: 52%;
  top: 30%;
  z-index: -10;
}

.white-gradient2 {
  position: absolute;
  width: 20rem;
  height: 20rem;
  background: #48a9f84b;
  filter: blur(100px);
  border-radius: 100px;
  right: 30%;
  top: 60%;
  z-index: -10;
}

.white-gradient4 {
  position: absolute;
  width: 20rem;
  height: 20rem;
  background: #2f52ff67;
  filter: blur(140px);
  border-radius: 100px;
  right: 60%;
  top: 120%;
  z-index: -10;
}
.white-gradient5 {
  position: absolute;
  width: 20rem;
  height: 20rem;
  background: #61dafb37;
  filter: blur(140px);
  border-radius: 100px;
  right: 18%;
  top: 60%;
  z-index: -10;
}

.white-gradient6 {
  position: absolute;
  width: 8rem;
  height: 8rem;
  background: #768dffb3;
  filter: blur(100px);
  border-radius: 100px;
  right: 18%;
  top: 230%;
  z-index: -10;
}

.white-gradient8 {
  position: absolute;
  width: 27rem;
  height: 27rem;
  background: #284af8a0;
  filter: blur(150px);
  border-radius: 100px;
  right: 60%;
  top: 280%;
  z-index: -10;
}
.white-gradient9 {
  position: absolute;
  width: 33rem;
  height: 33rem;
  background: #985efb;
  border-radius: 100px;
  right: 60%;
  top: 120%;
  z-index: -10;
}

.white-gradient10 {
  position: absolute;
  width: 14rem;
  height: 14rem;
  background: #61dafb56;
  filter: blur(120px);
  border-radius: 30px;
  right: 45%;
  top: 430%;
  z-index: -10;
}

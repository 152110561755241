@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: linear-gradient(-45deg, #09002e, #06070f, #02000f, #0a0231);
  background-size: 400% 400%;
  animation: gradient 8s ease-in-out infinite;
  height: auto;
}
p {
  color: #c7c7c7;
}

/* background animation */
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.btn {
  background: linear-gradient(92.23deg, #2f5cff 21.43%, #61dafb 117.04%);
  color: #0c0135;
  width: 170px;
  height: 50px;
  transition: all 400ms ease;
}

.btn-contact {
  background: linear-gradient(92.23deg, #2f5cff 21.43%, #61dafb 117.04%);
  color: #0c0135;
  width: 170px;
  height: 50px;
  transition: all 400ms ease;
}

.btn:hover {
  box-shadow: 0px 4.42184px 107.27px #2f5cff;
  color: #fff;
}

.btn-2 {
  color: white;
  width: 170px;
  height: 50px;
  transition: all 400ms ease;
}

.btn-2:hover {
  box-shadow: 0px 4.42184px 107.27px #2f5cff;
  color: #2f5cff;
}

.active {
  /* background: #2f5cff;
  background: linear-gradient(31deg, #2f5cff 0%, #2f5cff 31%, #61dafb 100%); */
  color: #2f5cff;
  border-radius: 14px;
}
.btn-3 {
  width: 170px;
  height: 50px;
  transition: all 400ms ease;
}

.text-accent-2 {
  color: #9d9d9d;
}

.text-accent-3 {
  background: #2f5cff;
  background: linear-gradient(to right, #2f5cff 13%, #61dafb 72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-accent-4 {
  background: #969696;
  background: linear-gradient(to right, #969696 0%, #ffffff 33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@layer base {
  body {
    @apply text-lg leading-8 text-white font-secondary;
  }
  .h2 {
    @apply font-primary font-semibold text-[38px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-secondary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn,
  .btn-2 {
    @apply font-medium text-white rounded-2xl font-primary;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#2f5cff ] to-[#61DAFB ] font-bold font-primary hover:from-[#61DAFB ] hover:to-[#2f5cff];
  }
  .section {
    @apply flex items-center py-8 lg:py-24 lg:h-screen;
  }
}

.glass {
  opacity: 0.8;
  background: linear-gradient(
    38deg,
    rgba(19, 29, 49, 0.447) 21%,
    #1f2a6377 68%,
    #151b3b89 95%
  );
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  z-index: 10;
  text-align: center;
}

/* Service  */
.btn-links {
  width: 30px;
  height: 30px;
}

.links {
  display: flex;
}

.animation {
  width: 450px;
  position: relative;
  right: 60px;
}
/* Animations */

/* fadeUp */
@keyframes fadeInUp {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.fadeInUp {
  animation: 2.7s fadeInUp;
}

/* fadDown */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation: 2.7s fadeInDown;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* animation */

@keyframes appear {
  from {
    opacity: 0;
    scale: 0.8;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}

.animationA {
  animation: appear linear;
  animation-timeline: view();
  animation-range: entry 25% cover 50%;
}

/* Responsive */

@media (min-width: 641px), (max-width: 768px) {
  .box {
    margin: 0 auto;
    width: 21rem;
    height: 21rem;
  }
  .box img {
    width: 90%;
  }
  .btn-banner {
    width: auto;
    margin: 0 auto;
  }
  .skills {
    width: 100%;
  }
  .r-card {
    height: 430px;
  }
  .details {
    margin-bottom: 3rem;
  }
  .skills-items {
    gap: 50rem;
  }
  .skills .h3 {
    font-size: 30px;
  }
}

@media (max-width: 640px) {
  .box {
    width: 16rem;
    height: 16rem;
  }
  .box img {
    width: 90%;
  }
  .h1-span {
    font-size: 55px;
  }
  .btn-banner {
    width: auto;
    margin: 0 auto;
  }
  .about {
    width: 100%;
  }

  .skills {
    margin-left: 0;
  }
  .details {
    margin-bottom: 6rem;
  }
  #social {
    margin-top: 12px;
  }

  .skills-items {
    gap: 3rem;
  }
  .skills .h3 {
    font-size: 20px;
  }
  .s-left {
    width: 100%;
  }
  .nav-section {
    width: 400px;
  }
  .tabs {
    font-size: 16px;
    gap: 0px;
    padding: 0;
  }
}

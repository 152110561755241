.tabs {
  padding: 0;
  color: #2f5cff;
}
.r-container {
  border: 2px white;
  overflow: hidden;
  position: relative;
}

.r-card {
  transition: all 300ms ease;
  height: 26rem;
  border-radius: 12px;
  display: flex;
  cursor: pointer;
  transform-origin: top bottom;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.r-card img {
  border-radius: 12px;
  height: 26rem;
  width: 34rem;
}

.swiper-horizontal {
  overflow: visible;
}

.r-buttons {
  position: absolute;
  top: -3.5rem;
  right: 0;
}

.r-buttons button {
  font-size: 1.4rem;
  background: none;
  width: 30px;
  height: 30px;
  color: #fff;
  border-radius: 10px;
  transition: all 400ms ease;
  cursor: pointer;
}

.r-buttons > :nth-child(1) {
  background-color: #2f5cff;
  border: none;
}

.box {
  background-size: 300% 300%;
  border: solid 11px transparent;
  animation: gradient_box 5s ease infinite;
  background-image: linear-gradient(#212121, #212121),
    linear-gradient(
      137.48deg,
      #61dafb 30%,
      #5969b9 45%,
      #2f5cff 67%,
      #041672 87%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  justify-content: center;
  align-items: center;
  transition: all 400ms ease;
}

.animate-gradient {
  background-size: 300%;
  -webkit-animation: animatedgradient 6s ease infinite alternate;
  -moz-animation: animatedgradient 6s ease infinite alternate;
  animation: animatedgradient 6s ease infinite alternate;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#social a {
  transition: all 400ms ease;
  position: relative;
}

#social a:hover {
  color: #2f50ff;
  transform: scale(1.2);
  box-shadow: 0px 4.42184px 107.23px #2f52ff7c;
}

.box:hover {
  box-shadow: 0px 4.42184px 107.23px #2f52ff7c;
}

/* animations */

@keyframes gradient_box {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
